.anchor {
  text-decoration: none;
  color: inherit;
  pointer-events: none;
}

.has-side-bar {
  height: 100%;
  left: 0;
  position: relative;
  z-index: 2;

  &.mobile {
    position: absolute;
  }
}

.has-side-bar-menu-item {
  vertical-align: middle;
  margin-bottom: 2px;
}
