@import './colors.scss';

.has-container-tile {
  padding: 10px;
  background-color: $white;
  border: 1px solid $gray-accent-light;
}

.has-collapse {
  border: 0px;
  background-color: $white;

  &.has-collapse-panel {
    border: 0px;
    user-select: none;

    > .ant-collapse-header {
      padding: 5px 25px;

      > .ant-collapse-arrow {
        left: 0;
      }
    }

    > .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.has-text-primary {
  color: $primary-blue !important;
}

.font-weight-semibold {
  font-weight: 600;
}

.has-avatar {
  height: 48px;
  width: 48px;
}

@media (max-width: 1919px) {
  .has-avatar {
    height: 48px;
    width: 48px;
  }
}

.right-0 {
  right: 0;
}

.z-index-1 {
  z-index: 1;
}

.has-incident-card {
  transition: border 0s;
  border: 1px solid $gray-accent-light;

  .ant-card-body {
    padding: 5px;
  }

  &:hover {
    background-color: $dashboard-card-hover-bg;
    border: 2px solid $dashboard-card-hover-border;
    .ant-card-body {
      margin: -1px;
    }
  }
}

@media (max-height: 670px) and (min-width: 961px) {
  .has-incident-card {
    min-width: 100px;
  }
}

.has-min-vh-100 {
  min-height: calc(100vh - 64px);
}

.h-33 {
  height: calc(100% / 3 + 5px);
}

.investigation-entry-edit {
  &.active {
    color: $secondary-green;
  }

  &:hover {
    color: $secondary-green;
  }
}

.list-item-interactive {
  transition: all ease-in-out 300ms;
  cursor: pointer;

  &:hover {
    background-color: #e6f0f5;
  }

  &.active {
    background-color: #e6f0f5;
  }
}

.icon-hover {
  transition: all ease-in-out 300ms;

  &.danger {
    &:hover {
      color: $hover-danger;
    }
  }

  &.warning {
    &:hover {
      color: $hover-warning;
    }
  }

  &.primary {
    &:hover {
      color: $primary-blue;
    }
  }

  &.half-opacity {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &.primary-vibrant {
    &:hover {
      color: $hover-primary-vibrant;
    }
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.has-dot {
  &.ant-select-item-option {
    &::before {
      height: 8px;
      width: 8px;
      background-color: $primary-blue;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      align-self: center;
      margin-right: 5px;
      content: '';
    }
  }
}

.has-gallery-image {
  position: relative;
  display: flex;
  justify-content: center;
  height: 125px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 300ms ease-in-out;
  }

  &:hover {
    img {
      filter: brightness(0.2);
    }

    > *:not(img) {
      opacity: 1;
    }
  }

  > *:not(img) {
    position: absolute;
    align-self: center;
    opacity: 0;
    color: $white;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}

@media (max-width: '992px') {
  .has-gallery-image {
    height: 250px;
  }
}

.has-chatbot-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  opacity: 0.3;
  transition: all 300ms ease-in-out;

  &:hover,
  &.has-fetching {
    opacity: 1;
    bottom: 20px;
    right: 20px;
  }
}

.has-new-report .ant-modal-content .ant-modal-header {
  background-color: $gray-light;
  padding: 20px;
  border-bottom: 1px solid $new-report-modal-border;
}

.has-chat-bot-component {
  margin: 0px 10px 10px 10px;
  padding: 12px;
  border-radius: 18px 18px 0px 18px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.has-row-max-height {
  max-height: 40px;
}

.has-hidden-incident {
  opacity: 0.5;
}

.has-contact-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.has-contact-details {
  padding: 40px;
}

.has-contact-map {
  height: auto;
  padding: 40px;
}

@media (max-width: '992px') {
  .has-contact-map {
    height: 400px;
    padding: 0px 20px 20px 20px;
  }

  .has-contact-details {
    padding: 20px;
  }
}

.mh-0 {
  min-height: 0;
}

.text-color {
  &.primary {
    color: $primary-color;
  }

  &.primary-blue {
    color: $primary-blue;
  }

  &.secondary {
    color: $secondary-green;
  }
}

.language-picker-option {
  margin-right: 10px;
  transition: all 300ms ease-in-out;

  > .flag {
    font-size: 24px;
    opacity: 0.5;
    transition: all 300ms ease-in-out;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.cursor {
  &.pointer {
    cursor: pointer;
  }
}

.line-height-normal {
  line-height: normal;
}

.scrollbox {
  margin: auto -10px;
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 200px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.has-document-card {
  border-width: 2px;

  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  transition: all 300ms ease-in-out;
}

.has-charts-container {
  background: #f0f2f5;
  border: unset;
  .chart-panel {
    .react-grid-item {
      border: solid 1px black;
    }
  }
  .no-borders .react-grid-item .ant-collapse {
    border: unset;
    background-color: white;
  }
  .no-borders .react-grid-item .ant-collapse-item {
    border-bottom: unset;
  }
  .no-borders .react-grid-item .ant-collapse-item-active {
    border-bottom: unset;
  }
  .has-chart-events-table-height {
    height: calc(100vh - 100px);
  }
  .ant-collapse-content-box {
    background: #f0f2f5;
  }
  .ant-collapse-header {
    background: #f0f2f5;
  }
}

.has-sql-container {
  width: 95%;
  .sql-textarea-width {
    width: 80%;
    margin: 10px;
    .ace_editor,
    .ace_editor * {
      font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas', monospace !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      letter-spacing: 0 !important;
    }
  }
  .font-weight-bold {
    font-weight: bold;
  }
}

.has-chart-library {
  .ant-list {
    border-right: 1px solid #dfdfdf;
    width: 25%;
    height: 100%;
    .ant-list-header {
      display: flex;
      justify-content: center;
    }
    .ant-spin-nested-loading {
      height: calc(100% - 57px);
      overflow: auto;

      .ant-list-item {
        cursor: pointer;
        justify-content: center;
        &:hover {
          background-color: $dashboard-card-hover-bg;
        }
      }
    }
  }
}

.ant-carousel .slick-dots li button {
  background: rgba(251, 150, 48, 0.7);
  height: 10px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: rgb(251, 150, 48);
  height: 10px;
}
.modalStyling {
  padding: 0;
  img {
    object-fit: contain;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: stretch;
  }
}

.bg {
  &--danger {
    background-color: rgba($hover-danger, 0.1);
  }
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: #52c41a;
  border-radius: 50%;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: #f5222d;
  border-radius: 50%;
}

.orange-dot {
  height: 10px;
  width: 10px;
  background-color: #ebb802;
  border-radius: 50%;
}

.italic-text {
  font-style: italic;
}
