.company-details {
  grid-area: companyDetails;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.divisions-list {
  grid-area: divisionList;
  display: flex;
  flex-direction: column;
  padding-left: 100px;
}

.tabbed-settings {
  grid-area: tabbedSettings;
  display: flex;
  flex-direction: column;
}

.grid-settings-container {
  display: grid;
  height: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto 60%;
  grid-gap: 5px;
  grid-template-areas:
    'companyDetails divisionList'
    'tabbedSettings tabbedSettings';
}

@media (min-width: 961px) and (max-width: 1280px) {
  .divisions-list {
    padding-left: 20px;
  }

  .grid-settings-container {
    display: grid;
    height: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto 50%;
    grid-gap: 5px;
    grid-template-areas:
      'companyDetails divisionList'
      'tabbedSettings tabbedSettings';
  }
}

@media (min-width: 0px) and (max-width: 960px) {
  .company-details {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .divisions-list {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .tabbedSettings {
    margin-bottom: 20px;
  }

  .grid-settings-container {
    display: grid;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    grid-template-areas:
      'companyDetails'
      'divisionList'
      'tabbedSettings';
  }
}
