// Base colors

$primary-color: #fb9630;
$primary-blue: #3b79b6;
$secondary-green: #429e9d;
$secondary-green-half: #a0cece;

$white: #ffffff;
$black: #000000;
$gray: #666666;
$gray-light: #f9f9f9;
$gray-accent-light: #dfdfdf;

$hover-danger: #f5222d;
$hover-warning: #fa541c;
$hover-primary: #3b79b6;
$hover-primary-vibrant: #1890ff;

// For components

$side-bar: #485465;

$steps-border-light-gray: #e0e0e0;

$dashboard-card-hover-bg: #fafafa;
$dashboard-card-hover-border: #e8e8e8;

$new-report-modal-border: #f0f0f0;

$app-switcher-hover: #e4f3ff;
$app-switcher-icon: rgba(255, 255, 255, 0.65);
