@import '../../shared/styles/colors.scss';

.has-account-validation-container {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.has-account-validation-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 500px;
  min-height: 400px;
  padding: 48px;
  background-color: $white;
  border: 1px solid $gray-accent-light;
}
