@import '../../shared/styles/colors.scss';

.has-login-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
}

.has-login-container {
  background-color: $white;
  padding: 48px 48px 16px 48px;
  border: 1px solid $gray-accent-light;
  max-height: 100%;
  overflow: auto;
}

.has-login-tabs {
  width: 500px;
}

.has-login-header {
  margin-bottom: 20px;
  flex-direction: row;
}

.has-login-form {
  min-width: 400px;
}

.has-session-error {
  width: 400px;
  max-width: 400px;
}

@media (min-width: 0px) and (max-width: 960px) {
  .has-login-container {
    background-color: $white;
    padding: 10px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .has-login-tabs {
    width: auto;
    vertical-align: middle;
  }

  .has-login-header {
    width: 100%;
  }

  .has-login-form {
    width: 400px;
    min-width: 160px;
  }

  .has-login-error {
    width: auto;
  }
}

.has-register-selector {
  height: 100% !important;

  > .ant-select-selector {
    height: 100% !important;
  }
}
