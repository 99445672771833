@charset "UTF-8";

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\27A4' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir='rtl'] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-next-character !important;
    -webkit-transform: rotatey(180deg);
    transform: rotatey(180deg);
    display: inline-block;
  }
}

.slick-next {
  right: -25px;
  [dir='rtl'] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character !important;
  }
}
