.incidentDetails1 {
  grid-area: incidentDetails1;
  display: flex;
}
.incidentDetails2 {
  grid-area: incidentDetails2;
  display: flex;
}
.incidentDetails3 {
  grid-area: incidentDetails3;
  display: flex;
}
.tabs {
  grid-area: tabs;
  display: flex;
}
.imageSection {
  grid-area: imageSection;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1281px) {
  .grid-incident-details-container {
    display: grid;
    height: 100%;
    grid-template-columns: 3fr 5fr 3fr;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      'incidentDetails1 tabs imageSection'
      'incidentDetails2 tabs imageSection'
      'incidentDetails3 tabs imageSection'
      'incidentDetails3 tabs imageSection';
  }
}

@media (min-width: 0px) and (max-width: 960px) {
  .grid-incident-details-container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas:
      'incidentDetails1'
      'incidentDetails2'
      'incidentDetails3'
      'tabs'
      'imageSection';
  }
}

@media (min-width: 961px) and (max-width: 1280px) {
  .grid-incident-details-container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      'incidentDetails1 tabs'
      'incidentDetails2 tabs'
      'incidentDetails3 imageSection';
  }
}

.has-incidents-table-height {
  height: calc(100vh - 64px - 5px - 5px);
}

.has-hidden-incident-page {
  opacity: 0.7;
}
