@import '../../shared/styles/colors.scss';

.has-fallback-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  height: 100%;
}

.has-not-found-icon {
  color: $secondary-green;

  .question {
    font-size: 5em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 10px;
    color: $primary-blue;
  }

  .folder {
    font-size: 15em;
  }
}
