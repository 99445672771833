.team-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: all ease-in-out 300ms;
  height: 100%;

  &.hidden {
    flex-basis: 50%;
  }

  @media (min-width: 0px) and (max-width: 960px) {
    &.hidden {
      width: 0;
      height: 0;

      & > * {
        display: none;
      }
    }
  }
}

.team-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: 50%;
  margin-left: 32px;
  height: 100%;
  width: 100%;
}
