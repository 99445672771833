@import '../../../shared/styles/colors.scss';

.has-header {
  height: 64px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: all 200ms ease-in-out;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  justify-content: center;
  word-break: break-all;
}

.header-modal-toggle {
  display: none;
}

.header-non-title-container {
  height: 64px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > .header-company-selector {
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 1;

    > .has-company-selector {
      width: 50%;
      max-width: 200px;
      margin-right: 8px;
      cursor: pointer;
    }

    > .has-division-selector {
      width: 50%;
      max-width: 240px;
      cursor: pointer;
      margin-right: 8px;
    }
  }

  > .header-language-picker-and-user-profile-container {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > .header-language-picker {
      display: flex;
      flex-direction: row;
      align-self: center;
      margin-left: 8px;
      margin-right: 8px;
    }

    > .header-user-profile {
      cursor: pointer;
      padding-left: 1%;
      padding-right: 1%;
      margin-left: 1%;
      margin-right: 1%;
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .has-header {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    padding: 0 25px !important;

    &.show-extra {
      height: auto;
      max-height: 256px;

      .header-non-title-container {
        height: 64px;
        display: flex;
        flex-direction: column;
        padding-left: 24px;

        > .header-company-selector {
          display: inline-flex;
          flex-direction: row;
          flex-grow: 1;

          > .has-company-selector {
            width: 50%;
            max-width: 200px;
            margin-right: 8px;
            cursor: pointer;
          }

          > .has-division-selector {
            width: 50%;
            max-width: 240px;
            cursor: pointer;
            margin-right: 8px;
          }
        }

        > .header-language-picker-and-user-profile-container {
          height: 64px;
          display: flex;
          flex-direction: row;
          justify-content: flex-endend;

          > .header-language-picker {
            display: flex;
            flex-direction: row;
            align-self: center;
            margin-left: 8px;
            margin-right: 8px;
          }

          > .header-user-profile {
            cursor: pointer;
            padding-left: 1%;
            padding-right: 1%;
            margin-left: 1%;
            margin-right: 1%;
          }
        }
      }
    }
  }

  .header-title-container {
    width: 75%;
    height: 64px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    span:nth-of-type(2) {
      display: none;
    }
  }

  .header-company-selector {
    display: none;
  }

  .header-language-picker {
    display: none;
  }

  .header-modal-toggle {
    height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (min-width: 376px) and (max-width: 767px) {
  .has-header {
    &.show-extra {
      max-height: 180px;

      > .header-company-selector {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-grow: 1;

        > .has-company-selector {
          width: 50%;
          margin-right: 4px;
        }

        > .has-division-selector {
          width: 50%;
          max-width: 50%;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .has-header {
    justify-content: space-between;
  }

  .header-title-container {
    flex-shrink: 1;
  }

  .header-company-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 8px;
    width: 100%;

    > .has-company-selector {
      width: 50%;
      max-width: 50%;
      margin-right: 4px;
    }

    > .has-division-selector {
      width: 50%;
      max-width: 50%;
    }
  }
}

@media (min-width: 961px) and (max-width: 1366px) {
  .has-header {
    justify-content: space-between;
  }

  .header-company-selector {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 0 8px;
  }

  .has-division-selector {
    margin-right: 32px;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .has-header {
    justify-content: space-between;
  }

  .header-company-selector {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 0 40px;
  }
}

@media (min-width: 1921px) {
  .has-header {
    justify-content: space-between;
  }

  .header-company-selector {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 40px;
  }
}
