@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~antd/dist/antd.css';
@import './shared/styles/colors.scss';

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
  box-shadow: 0 0 0 1000px $white inset !important;
}
