@import './colors.scss';

.ant-btn {
  min-width: 100px;

  & > .anticon {
    vertical-align: middle;
    margin-bottom: 3px;
  }
}

.ant-descriptions-title {
  margin-bottom: 10px;
  font-size: 14px;
  color: $primary-color;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $steps-border-light-gray;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: $steps-border-light-gray;
}

.ant-table-thead > tr > th {
  background-color: $white;
}

.ant-table-tbody > tr {
  cursor: pointer;
}

.ant-table-small {
  border: 0;
  font-size: 12px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px;
}

.ant-radio-group {
  align-items: center;
  justify-content: space-evenly;
}

.ant-radio-button-wrapper {
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid $primary-blue;

  &:first-child {
    margin-left: 0;
    border-radius: 6px;
    border: 1px solid $primary-blue;
  }

  &:last-child {
    margin-right: 0;
    border-radius: 6px;
  }

  &:not(:first-child)::before {
    content: none;
  }

  &:first-child::before {
    content: none;
  }

  &:hover {
    background-color: $primary-blue;
    color: $white;
  }

  &.switch-radio-button {
    margin-right: 0;
    margin-bottom: 0;

    &:hover {
      background-color: initial;
      color: initial;
    }
    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}

.ant-list-item-meta-description {
  line-height: 1.33;
}

.ant-alert-message {
  font-size: 14px !important;
  font-weight: 500;
}

.ant-alert-description {
  font-size: 12px;
  font-weight: 500;
}

.ant-notification-notice-message {
  font-weight: 500;
}

.ant-modal-header {
  border-bottom: none;
  text-align: center;
  padding: 40px;

  > * {
    font-size: 18px;
    font-weight: 600;
  }
}

.ant-modal-footer {
  border-top: none;
  padding: 20px;

  > * {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ant-row {
  &.ant-form-item {
    padding: 2px;
  }
}

.ant-col {
  &.ant-form-item-label {
    > * {
      font-weight: 500;
      color: $gray;
    }
  }
}

.ant-input {
  &.ant-input-lg {
    font-size: 14px;
  }
}

.ant-input-prefix,
.ant-input-suffix,
.ant-picker-suffix,
.ant-picker-clear {
  display: flex;
  align-items: center;
}

* .ant-descriptions-row {
  > td:nth-child(odd) {
    padding: 0px 0px 5px 0px;
  }
}

.ant-descriptions-item-label {
  color: $gray;
  font-size: 14px;
}
.ant-descriptions-item-content {
  color: $black;
  font-size: 14px;
  padding-bottom: 8px;
}

.ant-select-selector {
  > span {
    pointer-events: none;
  }
}

.ant-message-error {
  display: flex;
  align-items: center;
}

.rsc {
  > * {
    user-select: none;
  }
}

.rsc-container {
  height: 100%;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
}

.ant-popover-inner-content {
  max-width: 400px;
}

.ant-upload-list-item {
  background-color: $white;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background-color: $white;
}

.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  flex-grow: 1;
  max-height: 100%;
}

.ant-table-header > table {
  visibility: visible !important;
}
