@import './../../../shared/styles/colors.scss';

.menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $black;
  max-height: 40px;
  background-color: $white;

  &:hover {
    background-color: $app-switcher-hover;
    color: $black;
  }
}

.icon {
  color: $app-switcher-icon;
  &:hover {
    color: $white;
  }
}
