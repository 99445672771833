.barChart1 {
  grid-area: barChart1;
  display: flex;
}
.tiles {
  grid-area: tiles;
  display: flex;
}
.recentActivity {
  grid-area: recentActivity;
  display: flex;
}
.heatmap {
  grid-area: heatmap;
  display: flex;
}
.actionsTable {
  grid-area: actionsTable;
  display: flex;
}
.barChart2 {
  grid-area: barChart2;
  display: flex;
}
.pieChart1 {
  grid-area: pieChart1;
  display: flex;
}
.pieChart2 {
  grid-area: pieChart2;
  display: flex;
}

@media (min-width: 0px) and (max-width: 960px) {
  .grid-container {
    display: grid;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas:
      'tiles'
      'recentActivity'
      'actionsTable'
      'barChart1'
      'pieChart1'
      'pieChart2'
      'barChart2'
      'heatmap';
  }

  .barChart1 {
    height: 200px;
    max-height: 200px;
  }

  .barChart2 {
    height: 200px;
    max-height: 200px;
  }

  .recentActivity {
    height: 300px;
    max-height: 300px;
  }

  .actionsTable {
    height: 300px;
    max-height: 300px;
  }

  .pieChart1 {
    height: 200px;
    max-height: 200px;
  }

  .pieChart2 {
    height: 200px;
    max-height: 200px;
  }

  .heatmap {
    height: 300px;
    max-height: 300px;
  }
}

@media (min-width: 961px) and (max-width: 1366px) {
  .grid-container {
    display: grid;
    height: 100%;
    grid-template-columns: 37.5% auto 37.5%;
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      'barChart1 tiles recentActivity'
      'barChart1 tiles recentActivity'
      'pieChart1 tiles recentActivity'
      'pieChart1 tiles recentActivity'
      'pieChart2 heatmap actionsTable'
      'pieChart2 heatmap actionsTable'
      'barChart2 heatmap actionsTable'
      'barChart2 heatmap actionsTable';
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .grid-container {
    display: grid;
    height: 100%;
    grid-template-columns: 37.5% auto 37.5%;
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      'barChart1 tiles recentActivity'
      'barChart1 tiles recentActivity'
      'pieChart1 tiles recentActivity'
      'pieChart1 tiles recentActivity'
      'pieChart2 heatmap actionsTable'
      'pieChart2 heatmap actionsTable'
      'barChart2 heatmap actionsTable'
      'barChart2 heatmap actionsTable';
  }
}

@media (min-width: 1921px) {
  .grid-container {
    display: grid;
    height: 100%;
    grid-template-columns: 37.5% auto 37.5%;
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      'barChart1 tiles recentActivity'
      'barChart1 tiles recentActivity'
      'pieChart1 heatmap recentActivity'
      'pieChart1 heatmap recentActivity'
      'pieChart2 heatmap actionsTable'
      'pieChart2 heatmap actionsTable'
      'barChart2 barChart2 actionsTable'
      'barChart2 barChart2 actionsTable';
  }
}

@media (min-width: 0px) and (max-width: 960px) {
  .grid-tiles-container {
    display: grid;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 5px;
    grid-template-areas:
      't1 t2'
      't3 t4'
      't5 t6';
  }
}

@media (min-width: 961px) {
  .grid-tiles-container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
    grid-template-areas:
      't1 t2 t3'
      't4 t5 t6';
  }
}

@media (max-height: 670px) and (min-width: 961px) {
  .grid-tiles-container {
    display: grid;
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas: 't1 t2 t3 t4 t5 t6';
  }

  .tiles {
    overflow-x: auto;
    padding-bottom: 2px;
  }
}

.t1 {
  grid-area: t1;
}
.t2 {
  grid-area: t2;
}
.t3 {
  grid-area: t3;
}
.t4 {
  grid-area: t4;
}
.t5 {
  grid-area: t5;
}
.t6 {
  grid-area: t6;
}
