.grid-companies-container {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-gap: 5px;
  grid-template-areas: 'companiesList companyDetails';

  &:active {
    grid-template-columns: 1fr 1fr;
  }
}

.companies-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all ease-in-out 300ms;

  &.hidden {
    width: 60%;
  }

  @media (min-width: 0px) and (max-width: 960px) {
    &.hidden {
      width: 0;
      height: 0;

      & > * {
        display: none;
      }
    }
  }
}

.company-details-panel {
  display: none;

  &.active {
    display: flex;
    flex-grow: 1;
  }
}
