@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: opacity 0.8s;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #f1f1f1;
    background: #e8e8e8f7;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f8f8f;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8f8f8f;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
